import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import HeaderExpanded from '../components/HeaderExpanded/HeaderExpanded';
import StationConnectForm from '../components/StationConnectForm/StationConnectForm';
import { Helmet } from 'react-helmet';

function ConnectWeatherStation() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const title = 'Connect Your Weather Station';
  const image =
    process.env.GATSBY_S3_BUCKET_ASSETS + 'images/weather-station.jpg';

  return (
    <>
      {/* Add meta tags inside here. */}
      <Helmet>
        <title>HortPlus | Connect Your Weather Station</title>
        <meta name='description' content='Connect Your Weather Station' />
      </Helmet>
      <HeaderExpanded title={title} image={image} />
      <Container className='my-5'>
        <p className='mb-3 hp-font-28 hp-title text-center'>
          Connect Grower &amp; Trial Weather Stations
        </p>
        <StationConnectForm />
      </Container>
    </>
  );
}

export default ConnectWeatherStation;
