import React, { useState } from 'react';
import {
  Alert,
  Form,
  Button,
  Container,
  Row,
  Col,
  FormGroup
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';

const FAKE_GATEWAY_URL =
  'https://ld4ofkt5z4.execute-api.ap-southeast-2.amazonaws.com/production';
const required = 'This field is required';

export default () => {
  const [checked, setChecked] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    errors,
    reset,
    formState: { isSubmitting }
  } = useForm();

  const onSubmit = async (data) => {
    try {
      await fetch(FAKE_GATEWAY_URL, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        body: JSON.stringify(data),
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      });
      setSubmitted(true);
      reset();
    } catch (error) {
      setError(
        'submit',
        'submitError',
        `Oops! There seems to be an issue! ${error.message}`
      );
    }
  };

  const showSubmitError = (msg) => <Alert variant='warning'>{msg}</Alert>;

  const showThankYou = (
    <Container>
      <Row className='my-3 justify-content-center'>
        <Col md={8} className='align-content-center'>
          <Alert variant='success' className='pb-3 text-center'>
            A weather station connection request has been received. <br />
            We will contact you to update you on the status of this request
            soon.
          </Alert>
        </Col>
      </Row>
      <Row className='text-center justify-content-center'>
        <Col>
          <Button type='button' onClick={() => setSubmitted(false)}>
            Go back
          </Button>
        </Col>
      </Row>
    </Container>
  );

  const showForm = (
    <Container>
      <Row className='justify-content-center'>
        <Col md={6}>
          <Alert variant='primary'>
            <p>
              Connect your weather station to the <b>HortPlus Network</b> to
              securely access your weather station's data through tools and
              models on the MetWatch Platform.{' '}
            </p>
            <p>
              Your weather station data is private and will not be shared with
              other users.
            </p>
          </Alert>
        </Col>
      </Row>
      <Row className='justify-content-center my-3'>
        <Col md={6}>
          <Form onSubmit={handleSubmit(onSubmit)} method='post'>
            <Form.Group>
              <Form.Label>Your Email</Form.Label>
              <Form.Control
                type='email'
                name='email'
                id='email'
                placeholder='Your Email Address'
                ref={register({ required })}
                disabled={isSubmitting}
              />
              {errors.email && (
                <Alert variant='warning mt-1'>{errors.email.message}</Alert>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Your Name</Form.Label>
              <Form.Control
                type='text'
                name='name'
                id='name'
                placeholder='Your name'
                ref={register({ required })}
                disabled={isSubmitting}
              />
              {errors.name && (
                <Alert variant='warning mt-1'>{errors.name.message}</Alert>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type='text'
                name='phone'
                id='phone'
                placeholder='Your Phone Number'
                ref={register({ required })}
                disabled={isSubmitting}
              />
              {errors.phone && (
                <Alert variant='warning mt-1'>{errors.phone.message}</Alert>
              )}
            </Form.Group>

            <Form.Group>
              <Form.Label>Weather Station Type</Form.Label>

              <Form.Control
                as='select'
                name='station'
                id='station'
                ref={register({ required })}
                disabled={isSubmitting}
              >
                <option>Harvest</option>
                <option>Campbell Scientific</option>
                <option>Davis</option>
                <option>Other / Please specify in comment</option>
              </Form.Control>

              {errors.station && (
                <Alert variant='warning mt-1'>{errors.station.message}</Alert>
              )}
            </Form.Group>

            <Form.Group>
              <Form.Label>Comment</Form.Label>
              <Form.Control
                as='textarea'
                name='comment'
                id='comment'
                rows={5}
                placeholder='Comment'
                ref={register()}
              />
              {errors.comment && (
                <Alert variant='warning mt-1'>{errors.comment.message}</Alert>
              )}
            </Form.Group>
            <Form.Group className='text-center'>
              <span className='d-block text-center'>
                <b>Agreement</b>
              </span>
              <p>
                I am authorised to connect this weather station and acknowledge
                the HortPlus policy regarding user-supplied weather station
                data.
              </p>
              <Form.Check
                label='I Agree'
                id='agree'
                name='agree'
                ref={register()}
                onChange={(e) =>
                  setChecked(e.target.checked === true ? true : false)
                }
                disabled={isSubmitting}
              />
            </Form.Group>
            <FormGroup className='text-center'>
              <Button
                variant='primary'
                type='submit'
                disabled={isSubmitting || !checked}
              >
                Submit
              </Button>
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </Container>
  );

  return (
    <div className='page contact-page'>
      <div className='text-side'>
        {errors && errors.submit && showSubmitError(errors.submit.message)}
      </div>
      <div className='form-side'>{submitted ? showThankYou : showForm}</div>
    </div>
  );
};
